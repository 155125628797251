<template>
<div>
    <div class="row mwcNavbarBg m-0 align-items-center">
        <div class="col-4">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/barsIcon.svg" class="mwcbarsIcon cursor-pointer" alt="" data-bs-toggle="offcanvas" data-bs-target="#menuBarGuest" aria-controls="offcanvasRight">
        </div>
        <div class="col-4 text-center">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/MWCLogo.svg" alt="" class="mwcLogo" @click="homeWithoutLogin()">
        </div>
        <div class="col-4 p-0">
            <div class="d-flex align-items-center justify-content-end">
                <h2 class="mwcLoginTxt mb-0" @click="login()">Log in</h2>
                <button type="button" class="btn mwcSignUpTxt" @click="signUp()">Sign up</button>
                <em class="fa-light fa-bag-shopping mwcNavShopBagIcon" @click="cart()"></em>
            </div>
        </div>
    </div>
    <!-- web menu bar guest modal-->

    <div class="offcanvas offcanvas-end" tabindex="-1" data-bs-backdrop="static" id="menuBarGuest" aria-labelledby="offcanvasRightLabel">

        <div class="offcanvas-body menuBarGuestSection">
            <div class="row navModalSecHide m-0">
                <div class="col-4 p-0">
                    <em class="fa-regular fa-xmark modalCloseIcon mb-modalClose pl-0" data-bs-dismiss="offcanvas"></em>
                </div>
                <div class="col-4 p-0 text-center">
                    <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/MWCLogo.svg" alt="" class="mwcLogo" @click="menu_mwc()">
                </div>
                <div class="col-4 p-0 text-end">
                    <em class="fa-light fa-bag-shopping mwcNavShopBagIcon" aria-hidden="true" @click="menu_cart()"></em>
                </div>
            </div>
            <em class="fa-regular fa-xmark modalCloseIcon mb-modalClose navModalClose" data-bs-dismiss="offcanvas"></em>
            <div class="mob-modalImgSec">
                <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mobModalShutterStockImg.svg" alt="" class="mob-guestModalImg">
                <div class="mob-modalGuestSec">
                    <h2 class="mob-modalReadyToJoinTxt">Ready to join the club?</h2>
                    <p class="mob-modalSignUpTxt">Sign up today to start earning site credit and other gifts through our MWC referrals scheme and to keep up to date with the latest competitions and info. </p>
                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn mob-guestModalSignUpBtn" @click="signUp()">SIGN UP</button>
                    </div>
                </div>
            </div>
            <div class="mb-guestModalLatestTxtSecBg">
                <div class="d-flex align-items-center justify-content-between modalTextSecBg" @click="focusAllRaffles()">
                    <p class="modalLatestCompetitionTxt mb-0">LATEST COMPETITIONS</p>
                    <em class="fa-light fa-chevron-right modalChevronIcon"></em>
                </div>
                <div class="d-flex align-items-center justify-content-between modalTextSecBg" @click="winners()">
                    <p class="modalLatestCompetitionTxt mb-0">WINNERS</p>
                    <em class="fa-light fa-chevron-right modalChevronIcon"></em>
                </div>
                <div class="d-flex align-items-center justify-content-between modalTextSecBg" @click="how_to_play_withLogin()">
                    <p class="modalLatestCompetitionTxt mb-0">HOW TO PLAY</p>
                    <em class="fa-light fa-chevron-right modalChevronIcon"></em>
                </div>
                <div class="modalTextSecBg referralsMobHide" @click="get_referal_link()">
                    <p class="modalLatestCompetitionTxt mb-0">REFERRALS</p>
                    <p class="reffNewTxt mb-0">NEW</p>
                    <em class="fa-light fa-chevron-right modalChevronIcon"></em>
                </div>
                <div class="modalTextSecBg referralsWebHide" @click="get_referal_link_mob()">
                    <p class="modalLatestCompetitionTxt mb-0">REFERRALS</p>
                    <p class="reffNewTxt mb-0">NEW</p>
                    <em class="fa-light fa-chevron-right modalChevronIcon"></em>
                </div>
                <div class="d-flex align-items-center justify-content-between modalTextSecBg" @click="charity_page()">
                    <p class="modalLatestCompetitionTxt mb-0">CHARITY</p>
                    <em class="fa-light fa-chevron-right modalChevronIcon"></em>
                </div>
                <div class="d-flex align-items-center justify-content-between modalTextSecBg" @click="news()">
                    <p class="modalLatestCompetitionTxt mb-0">MWC NEWS</p>
                    <em class="fa-light fa-chevron-right modalChevronIcon"></em>
                </div>
            </div>
            <p class="haveAnAcntTxt">Have an account? <span class="signInTxtColor cursor-pointer" @click="login()">Sign in now</span></p>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn modalSignUpBtn" @click="signUp()">SIGN UP</button>
            </div>
            <div class="mb-faqsSec">
                <p class="mb-modalFaqsTxt mb-0 mt-0">
                    <span @click="faq_page()">FAQs</span>
                    <span class="mb-modalFaqsSeperator"> • </span>
                    <span @click="contact_page()">CONTACT</span>
                    <span class="mb-modalFaqsSeperator"> • </span>
                    <span @click="privacy_page()">PRIVACY</span>
                    <span class="mb-modalFaqsSeperator"> • </span>
                    <span @click="terms_page()">TERMS</span>
                </p>
            </div>
            <p class="modalFollowTxt">FOLLOW US</p>
            <div class="modalSocialMediaImgs">
                <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/fbDarkImg.svg" alt="">
                <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/twitterDarkImg.svg" alt="">
                <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/instagramDarkImg.svg" alt="">
                <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/tiktokDarkImg.svg" alt="">
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    MwcMixin
} from "@/mixins/pathName.js";
import '@/assets/css/welcomePage.css';

export default {
    mixins: [MwcMixin],
    methods: {
        login() {
            if (this.isMwcPath()) {
                dataLayer.push({
                    'event': 'sign_in_started'
                });
            }
            this.$router.push("/signIn");
        },
        signUp() {
            if (this.isMwcPath()) {
                dataLayer.push({
                    'event': 'sign_up_accessed'
                });
            }
            this.$router.push("/signUp");
        },
        how_to_play_withLogin() {
            this.$router.push("/howToPlayWithoutLogin");
        },
        cart() {
            this.$router.push("/cart");
        },
        charity_page() {
            this.$router.push("/charityWithoutLogin");
        },
        homeWithoutLogin() {
            this.$router.push("/");
        },
        winners() {
            this.$router.push("/winnersWithoutLogin")
        },

        focusAllRaffles() {
            const offcanvasElement = document.getElementById('menuBarGuest');
            offcanvasElement.classList.remove('show');
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            if (this.$route.path === '/') {
                this.$emit('focusAllRaffles');
            } else {
                this.$router.push("/");
            }
        },

        faq_page() {
            this.$router.push('/faqs')
        },
        contact_page() {
            this.$router.push('/contactUs')
        },
        privacy_page() {
            this.$router.push('/privacyPolicy')
        },
        terms_page() {
            this.$router.push('/termsAndConditions')
        },
        menu_mwc() {
            if (this.$route.path === '/') {
                const offcanvasElement = document.getElementById('menuBarGuest');
                offcanvasElement.classList.remove('show');
                $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            } else {
                this.$router.push("/")
            }
        },
        menu_cart() {
            if (this.$route.path === '/cart') {
                const offcanvasElement = document.getElementById('menuBarGuest');
                offcanvasElement.classList.remove('show');
                $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            } else {
                this.$router.push("/cart")
            }
        },

        get_referal_link() {
            this.$router.push('/signUp')
            localStorage.setItem('openReferral', true)
        },

        get_referal_link_mob() {
            this.$router.push('/signUp')
            localStorage.setItem('openReferralMob', true)
        },
        news() {
            this.$router.push("/news");
        },
    }
};
</script>
