<template>
<div class="charitySecBgWidth">
    <div class="position-relative mwcCharitySecWeb">
        <div class=" mwcCharityPartnerSecBg webMwcCharityImg" :style="{ 'background-image': `url(${urlLink +charityData.main_img_url})` }">
            <div class="row m-0">
                <div class="col-5 mob-charitySecWidth">
                    <div class="d-flex align-items-center mwcCharityImgs">
                        <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/sevenCleanSeasImg.svg" alt="" class="mwcOceanImg">
                        <hr class="mwcCharityHr m-0">
                        <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/monacoWatchClubImg.svg" alt="" class="monacoWatchClubImg">
                    </div>
                    <h6 class="mwcSupportOceantxt">We proudly support {{ charityData.name }}</h6>
                    <p class="mwcSupprtCharityDesc" ref="descriptionContainer4"></p>

                    <p class="mwcCoralTxtBg"><em class="fa-light fa-bottle-water mwcSpaIcon"></em> Together, we’ve helped clear <span class="mwcOceanTxtBg"> {{ Math.ceil(charityData.totalPlastic) }}kg</span> of ocean plastic!</p>
                    <button type="button" class="btn charityLearnMoreBtn" @click="charity_page()">LEARN MORE</button>
                </div>
            </div>

        </div>
    </div>

    <!-- mob charity -->
    <div class="mobCharitySecBg">
        <div class="mobCharitytxtBg">
            <p class="mwcOutCharitytxt">OUR CHARITY PARTNER</p>
            <h6 class="mwcSupportOceantxt text-center">We proudly support {{ charityData.name }}</h6>
        </div>
        <div class="w-100 charityImgMobile" :style="{ 'background-image': `url(${urlLink +charityData.mini_img_url})` }">
            <div class="mobCharityDetails">
                <p class="mwcSupprtCharityDesc text-center" ref="descriptionContainer3"></p>
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn charityLearnMoreBtn" @click="charity_page()">LEARN MORE</button>
                </div>
                <div class="d-flex align-items-center mwcCharityImgs">
                    <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/sevenCleanSeasImg.svg" alt="" class="mwcOceanImg">
                    <hr class="mwcCharityHr m-0">
                    <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/monacoWatchClubImg.svg" alt="" class="monacoWatchClubImg">
                </div>
                <div class="d-flex justify-content-center">
                    <p class="mwcCoralTxtBg"><em class="fa-light fa-bottle-water mwcSpaIcon"></em>Together, we’ve helped clear <span class="mwcOceanTxtBg"> {{ Math.ceil(charityData.totalPlastic) }}kg </span> of ocean plastic!</p>
                </div>

            </div>
        </div>
    </div>

</div>
</template>

<script>
import DOMPurify from 'dompurify';

import {
    urlLink,
    socialImgUrlLink
} from '@/mixins/s3bucket.js'
import {
    mapGetters
} from 'vuex';

export default {
    data() {
        return {
            charityData: [],
            urlLink: urlLink,
            socialImgUrlLink: socialImgUrlLink
        }
    },
    computed: {
        ...mapGetters(['getcharityData'])
    },
   
    created() {
        this.fetch_charity()
    },
    methods: {
        async fetch_charity() {
            try {
                await this.$store.dispatch('fetchCharity');
                this.charityData = this.getcharityData[0];
                this.setHtmlContent3();

            } catch (error) {
                console.error("Error fetching all raffles:", error);
            }
        },

        setHtmlContent3() {
            const sanitizedHtml3 = DOMPurify.sanitize(this.charityData.description);
            this.$refs.descriptionContainer3.innerHTML = sanitizedHtml3;
            this.$refs.descriptionContainer4.innerHTML = sanitizedHtml3;
        },

        charity_page() {
            this.$router.push("/charityWithoutLogin");
        }
    }
}
</script>
